import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Box, makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProposalTitleAndBody from './ProposalTitleAndBody';
import PointFiles from './PointFiles';
import PointMatrix from './PointMatrix';
import PointNotes from './PointNotes';
import clsx from 'clsx';
import isEmpty from '../../../../../utils/isEmpty';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
  getUserPeriodNotes,
  getUserPointNotes,
  uploadUserPeriodNotes,
  uploadUserPointNotes,
} from '../../../../../store/actions/meetingsActions';

const useStyles = makeStyles(theme => ({
  activeProposal: {
    padding: '30px',
  },
  blinkAnimation: {
    animation: `$pulseAnimation 1.5s ${theme.transitions.easing.easeInOut} infinite`,
  },
  noMargin: {
    marginBottom: 0,
  },
  proposalName: {
    marginTop: '3px',
    marginBottom: '4px',
  },
  buttonConfirm: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    marginBottom: '40px',
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
  },
  '@keyframes pulseAnimation': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

const ActiveProposal = ({
  active,
  isProposal,
  beginVote,
  disabled,
  moderator,
  voting,
  activeInfo,
  reunionEnd,
  activeHasStatus,
}) => {
  const classes = useStyles();
  const [blink, setBlink] = useState(true);

  const dispatch = useDispatch();
  const { id } = useParams();

  const isPeriod = !isEmpty(active.type);

  const sendNotes = useCallback(updatedNotes => {
    if (isPeriod) {
      dispatch(uploadUserPeriodNotes(id, active.type, { notes: updatedNotes }));
    } else {
      dispatch(uploadUserPointNotes(id, active.id, { notes: updatedNotes }));
    }
  }, [active]);

  const debouncedSendNotes = useCallback(debounce(sendNotes, 500), [active]);

  const handleNotesChange = notes => {
    debouncedSendNotes(notes)
  }

  useEffect(() => {
    if (blink) {
      setTimeout(() => {
        setBlink(false);
      }, 2900);
    }
  }, [blink]);

  useEffect(() => {
    setBlink(true);
  }, [active.id]);

  return (
    <>
      <Paper className={clsx(classes.activeProposal, { [classes.blinkAnimation]: blink })}>
        <ProposalTitleAndBody isPoint={isProposal} info={active} activeInfo={activeInfo} />
        {moderator && <PointMatrix matrix={active.matrix} />}
        <PointFiles files={active.attachments} proposalId={active.proposal_id} />
        {active.attachments && active.attachments.length > 0 && moderator && <Box height="20px" />}
        {moderator && <PointNotes info={active} isActive handleNotesChange={handleNotesChange} />}
      </Paper>
      {moderator && isProposal && !reunionEnd && !voting && !activeHasStatus ? (
        <Button
          fullWidth
          variant="contained"
          className={classes.buttonConfirm}
          onClick={beginVote}
          disabled={disabled}
        >
          Iniciar votação
        </Button>
      ) : (
        <Box height="40px" />
      )}
    </>
  );
};

ActiveProposal.defaultProps = {
  isProposal: false,
  beginVote: () => null,
  disabled: false,
  moderator: false,
  voting: false,
  activeInfo: {},
  reunionEnd: false,
  activeHasStatus: false,
};

ActiveProposal.propTypes = {
  active: PropTypes.object.isRequired,
  isProposal: PropTypes.bool,
  beginVote: PropTypes.func,
  disabled: PropTypes.bool,
  moderator: PropTypes.bool,
  voting: PropTypes.bool,
  activeInfo: PropTypes.object,
  reunionEnd: PropTypes.bool,
  activeHasStatus: PropTypes.bool,
};

export default React.memo(ActiveProposal);
